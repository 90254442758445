//
// Topbar
//

.topbar {
  // Buttons
  .btn.btn-custom {
    @include button-custom-variant(
      $color: null,
      $icon-color: var(--#{$prefix}white),
      $border-color: null,
      $bg-color: var(--#{$prefix}topbar-btn-bg-color),
      $color-active: null,
      $icon-color-active: var(--#{$prefix}white),
      $border-color-active: null,
      $bg-color-active: var(--#{$prefix}topbar-btn-bg-color-active)
    );
  }

  // Fixed header & NON sticky search modes
  .active.show > .btn.btn-custom {
    color: var(--#{$prefix}white);
    background-color: var(--#{$prefix}topbar-btn-bg-color);

    i,
    svg-icon {
      color: var(--#{$prefix}white);
    }
  }
}

// Desktop mode
@include media-breakpoint-up(lg) {
  .topbar-search {
    form {
      .search-input {
        @include placeholder(rgba($white, 0.75));
        background-color: var(--#{$prefix}topbar-btn-bg-color);
        border: 1px solid var(--#{$prefix}topbar-btn-bg-color);
        color: var(--#{$prefix}white);

        &:focus {
          background-color: var(--#{$prefix}topbar-btn-bg-color);
          border: 1px solid var(--#{$prefix}topbar-btn-bg-color);
          color: var(--#{$prefix}white);
        }
      }

      i,
      .svg-icon,
      .spinner-border {
        color: rgba($white, 0.75) !important;
      }
    }
  }
}
