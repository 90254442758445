.rnc__notification-item {
  border-left: none;

  .rnc__notification-timer {
    background-color: transparent;
  }
}

.rnc__notification-item--default {
  background-color: var(--bs-primary);
}
.rnc__notification-item--success {
  background-color: var(--bs-success);
}
.rnc__notification-item--warning {
  background-color: var(--bs-warning);
}
.rnc__notification-item--danger {
  background-color: var(--bs-danger);
}
.rnc__notification-item--info {
  background-color: var(--bs-info);
}
