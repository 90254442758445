//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Theme colors
// Primary
$primary: #1d87d2;
$primary-active: #1d87d2c2;
$primary-light: #1d87d234;
// $primary-light-dark: #173045;
$primary-inverse: #ffffff;

// Success
$success: #0C8346;
$success-active: #0c8346d4;
$success-light: #0c83465f;
// $success-light-dark: #0a3821;
$success-inverse: #ffffff;

// Info
$info: #6153CC;
$info-active: #6153ccd2;
$info-light: #6153cc5d;
// $info-light-dark: #2d2640;
$info-inverse: #ffffff;

// Danger
$danger: #C4311C;
$danger-active: #c4321cd2;
$danger-light: #c4321c52;
// $danger-light-dark: #54180f;
$danger-inverse: #ffffff;

// Warning
$warning: #F4B942;
$warning-active: #f4b942ce;
$warning-light: #f4b9425a;
// $warning-light-dark: #56360f;
$warning-inverse: #ffffff;

// Border radiuses
$border-radius: 0.75rem;
$border-radius-sm: 0.5rem;
$border-radius-lg: 1rem;

// Card
$card-border-radius: $border-radius-lg;

// Accordion
$accordion-border-radius: $border-radius;

// Button
$btn-border-radius-sm: $border-radius-sm;

// Accordion
$accordion-border-radius: $card-border-radius;

// Modals
$modal-content-border-radius: $card-border-radius;

// Badge
$badge-border-radius: $border-radius-sm;

$page-bg-dark: #1F354D;
$page-bg: #DADADA;
$content-bg: #f4f4f4;


$font-family-sans-serif: "IBM Plex Sans", Arial, Lucida Grande,  'sans-serif' !default;

$font-weight-light: 400 !default;
$font-weight-normal: 500 !default;
$font-weight-semibold: 600 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: 800 !default;